import { Injectable } from '@angular/core';
import {UserDataService } from "./user-data.service"
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize, tap, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient, private userData: UserDataService) { }

  settings = null

  refreshSettings() {
    this.settings = {}
    return this.http.get<any>("/api/v1/settings", { headers: new HttpHeaders().append("auth-token", this.userData.userData.token) })
    .pipe(tap(data => {
      data.forEach(setting => {
        this.settings[setting.setting_key] = setting.setting_value
      });
      console.log(this.settings)
    }))
  }

}

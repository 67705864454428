<body>
    <div id="login">
        <div class="container">
            <div id="login-row" class="row justify-content-center align-items-center">
                <div id="login-column" class="col-md-6">
                    <div class="login-area">
                        <h1>
                            <img src="assets/logo-big.png"> SafeHealth
                        </h1>
                        <div id="login-box" class="col-md-12">
                            <form id="login-form" class="form" (submit)="login(username, password)">
                                <h3>SIGN IN</h3>
                                <div class="form-group">
                                    <input [(ngModel)]="username" type="text" name="username" placeholder="User Name" id="username" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input [(ngModel)]="password" type="password" name="password" placeholder="Password" id="password" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="submit" name="Sign In" class="btn btn-info btn-md" value="Sign In">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

import { Injectable } from '@angular/core';
import {UserDataService } from "./user-data.service"
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize, tap, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class QuestionService {

  constructor(private http: HttpClient, private userData: UserDataService) { }


  questions = null
  result = null

  refreshQuestions() {
    this.result = null
    return this.http.get<any>("/api/v1/question", { headers: new HttpHeaders().append("auth-token", this.userData.userData.token) })
    .pipe(map(data => data.filter(data => !data.web_ignore)))
    .pipe(map(data => data.map(item => Object.assign({'asked': false}, item))))
    .pipe(map(data => data.map(item => Object.assign({'answer': null}, item))))
    .pipe(tap(data => {
      this.questions = data
    }))
  }

  saveQuestions(pass, failed_question_id) {
    var results = {"staff_id":this.userData.userData.id, "pass":pass, "kiosk_id":null, "failed_question_id":failed_question_id, "question_audit":this.questions}
    return this.http.post<any>("/api/v1/compliance", results, { headers: new HttpHeaders().append("auth-token", this.userData.userData.token) })
    .pipe(tap(data => {
      this.result = data
    }))
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(private http: HttpClient) { }

  userData = null

  login(username, password) {
    return this.http.post("/api/v1/login", {username:username, password:password})
    .pipe(tap(data => {
      this.userData = data
    }))
  }

  logout() {
    this.userData = null
  }
  

}

import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../services/user-data.service'
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public userData: UserDataService, private toastr: ToastrService, private router: Router) { 
    
  }

  ngOnInit(): void {
    this.userData.logout()
  }

  username = null
  password = null
  loading = false

  init() {
    this.username = null
    this.password = null
  }

  login(username, password) {

    this.userData.login(username, password)
    .subscribe(
      data => this.router.navigateByUrl('screening'),
      data => this.toastr.error('Failed to log in.')
      )
    this.init()
  }

}

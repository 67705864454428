import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../services/user-data.service'
import {Router} from '@angular/router';
import { QuestionService } from '../services/question.service'
import { SettingsService } from '../services/settings.service'
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss']
})
export class ScreeningComponent implements OnInit {

  constructor(public userData: UserDataService, private router: Router, public questionService: QuestionService, public settingsService: SettingsService) { }
  
  loaded = false
  currentQuestion = null
  questionCounter = 0
  textAnswer = null
  status = "running"
  failedCounter = 0

  ngOnInit(): void {
    if(!this.userData.userData) {
      this.router.navigateByUrl('login')
    } else {
      this.settingsService.refreshSettings().subscribe()
      this.questionService.refreshQuestions().subscribe(data => {
        this.loaded = true
        this.questionCounter = 0
        this.textAnswer = null
        this.currentQuestion = this.questionService.questions[0]
      })
    }
  }

  incrementQuestion() {
    if (this.questionCounter < this.questionService.questions.length - 1) {
      this.questionCounter++
      this.currentQuestion = this.currentQuestion = this.questionService.questions[this.questionCounter]
    } else {
      this.status = "passed"
      this.questionService.saveQuestions(true, null)
      .subscribe()
    }
  }

  failquestion() {
    this.failedCounter++
    if (this.failedCounter == this.settingsService.settings.fail_number || this.currentQuestion.is_hard_fail) {
      this.status = "failed"
      this.questionService.saveQuestions(false, this.currentQuestion.id)
      .subscribe()
    } else {
      this.incrementQuestion()
    }
  }

  submitTextAnswer(answer, typeId) {
    this.currentQuestion.answer = answer
    this.currentQuestion.asked = true
    
    if (typeId == 5) {
      var maxTemp
      console.log(this.userData.userData)
      if (this.userData.userData.custom_temp) {
        maxTemp = parseFloat(this.userData.userData.custom_temp)
      } else {
        maxTemp = parseFloat(this.settingsService.settings.temp_limit)
      }
      
      this.currentQuestion.correct_answer = maxTemp

      answer = parseFloat(answer)
      if (parseFloat(answer) >= maxTemp) {
        this.failquestion()
      } else {
        this.incrementQuestion()
      }
    } else {
      this.incrementQuestion()
    }
    this.textAnswer = null
  }

  submitBooleanAnswer(answer) {
    this.currentQuestion.answer = answer
    this.currentQuestion.asked = true
    if (this.currentQuestion.correct_answer == answer || answer == 'NA') {
      this.incrementQuestion()
    } else {
      this.failquestion()
    }
  }

  finish() {
    this.router.navigateByUrl('login')
  }



}

<div class="container d-flex h-100">
    <div class="row align-self-center content-box no-gutters">
        <div class="col">
            <h2>Screening for {{userData.userData.first_name}} {{userData.userData.last_name}}</h2>
            <div *ngIf="status == 'running'">
                <p>{{currentQuestion.question}}</p>
                <!-- Text/Numeric Input -->
                <div class="input-group mb-3">
                    <input [(ngModel)]="textAnswer" *ngIf="currentQuestion.type.id == 2" type="text" class="form-control">
                    <input [(ngModel)]="textAnswer" *ngIf="currentQuestion.type.id == 3 || currentQuestion.type.id == 5" type="number" class="form-control">
                </div>
                <div class="input-group mb-3" *ngIf="currentQuestion.type.id == 2 || currentQuestion.type.id == 3 || currentQuestion.type.id == 5">
                    <button [disabled]="!textAnswer" (click)="submitTextAnswer(textAnswer, currentQuestion.type.id)" class="btn btn-block btn-primary">Submit Answer</button>
                </div>
    
                <!-- Yes/No Input -->
                <div *ngIf="currentQuestion.type.id == 1" class="input-group mb-3">
                    <div class="col">
                        <button (click)="submitBooleanAnswer(true)" class="btn btn-block btn-primary">Yes</button>
                    </div>
                    <div class="col">
                        <button (click)="submitBooleanAnswer(false)" class="btn btn-block btn-primary">No</button>
                    </div>
                    <div class="col" *ngIf="currentQuestion.not_applicable_allowed">
                        <button (click)="submitBooleanAnswer('NA')" class="btn btn-block btn-primary">Not Applicable</button>
                    </div>
                </div>
            </div>

            <!-- Passed -->
            <div *ngIf="status == 'passed'">
                <h2><i class="fa fa-check-circle-o text-success large-icon" aria-hidden="true"></i></h2>
                <h2 *ngIf="questionService.result">{{questionService.result.time_completed | date:'short'}}</h2>
                <h2>Screening passed.</h2>
                <p>{{settingsService.settings.web_pass_text}}</p>
                <div class="input-group mb-3">
                    <button (click)="finish()" class="btn btn-block btn-primary">Log Out</button>
                </div>
            </div>

            <div *ngIf="status == 'failed'">
                <h2><i class="fa fa-times-circle-o text-danger large-icon" aria-hidden="true"></i></h2>
                <h2 *ngIf="questionService.result">{{questionService.result.time_completed | date:'short'}}</h2>
                <h2>Screening failed.</h2>
                <p>{{settingsService.settings.web_fail_text}}</p>
                <div class="input-group mb-3">
                    <button (click)="finish()" class="btn btn-block btn-primary">Log Out</button>
                </div>
            </div>
        </div>
    </div>
</div>
